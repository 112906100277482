import React from "react"
import uuidv4 from "uuid/v4"
import css from "@styled-system/css"

import { Box, Column, Container, DunkText, Flex, H1, Text } from "components"

const PasswordForm = ({
  password,
  setPassword,
  checkPassword,
  passwordWrong,
}) => {
  const id = uuidv4()

  return (
    <>
      <Flex
        maxWidth={512}
        as={"form"}
        onSubmit={event => {
          event.preventDefault()
          checkPassword(password)
        }}
      >
        <input
          autoFocus
          id={id}
          value={password}
          onChange={event => {
            setPassword(event.target.value)
          }}
          placeholder={`Enter Password`}
          type="password"
          css={css({
            flex: 1,
            minWidth: 0,
            m: 0,
            px: [3, 24],
            py: 3,
            border: 0,
            borderRadius: 0,
            fontFamily: "body",
            fontSize: 16,
            fontWeight: "body",
            lineHeight: "body",
            color: "text",
            bg: "background",
            appearance: "none",
            "&:focus": { outline: 0 },
            "::placeholder": {
              color: "text",
            },
          })}
        />
        <button
          children={`Submit`}
          type="submit"
          css={css({
            m: 0,
            px: [3, 4],
            py: 3,
            border: 0,
            borderRadius: 0,
            fontFamily: "body",
            fontSize: [1, 2],
            fontWeight: "bold",
            letterSpacing: "0.05em",
            textAlign: "center",
            textTransform: "uppercase",
            color: "text",
            bg: "primary",
            appearance: "none",
            cursor: "pointer",
            userSelect: "none",
            overflow: "hidden",
            "&:focus": { outline: 0 },
          })}
        />
      </Flex>
      {passwordWrong && <Text children={`Wrong password. Try again`} mt={3} />}
    </>
  )
}

const GateSection = props => (
  <Flex
    as="section"
    flexDirection="column"
    justifyContent="center"
    minHeight="100vh"
    pt={[96, 120]}
    bg="muted"
  >
    <Container py={[5, 6]}>
      <Column left>
        <H1 as="h1">
          <DunkText lines={["Get inspired"]} />
        </H1>
        <Text mt={[3, 4]} fontFamily="condensed" fontSize={[5, 6]}>
          You need a password to enter the gallery. If you don’t have one you
          can request it here.
        </Text>
      </Column>
      <Box mt={[3, 4]}>
        <PasswordForm {...props} />
      </Box>
    </Container>
  </Flex>
)

export default GateSection
