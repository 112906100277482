import React from "react"

import {
  Box,
  Column,
  Container,
  DunkText,
  H1,
  TabGroup,
  Text,
} from "components"

const OpeningSection = ({
  title,
  subtitle,
  activeSection,
  setActiveSection,
}) => (
  <Box as="section" pt={[96, 120]} bg="muted">
    <Container py={[5, 6]}>
      <Column left>
        <H1 as="h1">
          <DunkText lines={[title]} />
        </H1>
        <Text
          children={subtitle}
          mt={[3, 4]}
          fontFamily="condensed"
          fontSize={[5, 6]}
        />
      </Column>
      <Box mt={[3, 4]}>
        <TabGroup
          value={activeSection}
          onChange={event => {
            setActiveSection(event.target.value)
          }}
          tabs={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Commercial",
              value: "commercial",
            },
            {
              label: "Education",
              value: "education",
            },
            {
              label: "Healthcare",
              value: "healthcare",
            },
            {
              label: "Refreshment",
              value: "refreshment",
            },
          ]}
        />
      </Box>
    </Container>
  </Box>
)

export default OpeningSection
